import { useNavigate } from "react-router-dom";
import { CountryDropdown } from "react-country-region-selector";
import React, { useEffect, useRef, useState } from "react";
import {Button,Checkbox,DatePicker,Input,Radio,Select,Spin,notification} from "antd";
import {CloseOutlined,CloudUploadOutlined,EyeInvisibleOutlined,EyeTwoTone,LoadingOutlined,SmileOutlined} from "@ant-design/icons";
import {ADD_STUDENT,ADD_USER,PROGRAMS_LIST,STUDENTS_API,ROLE_LIST,SIS_STORAGE,NEW_ENROLLMENT,} from "../../../apis/apis";
import EnrollmentHeader from "../Header-and-Footer/enrollmentHeader";
import EnrollmentFooter from "../Header-and-Footer/enrollmentFooter";

const StudentOnlineApplication = () => {

    return(
        <>
       <EnrollmentHeader/>
       <div className="row">
        <h1>Enrollment Application</h1>
       </div>
       <EnrollmentFooter/>
        </>
    );
}

export default StudentOnlineApplication;