// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: #ffe386;
    padding: 20px;
    text-align: center;
  }
  
  .footer-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .logo {
    width: 100px;
    margin-bottom: 10px;
  }
  
  .address,
  .technical-assistance {
    margin: 10px 0;
    line-height: 27px;
  }
  
  .footer-links a {
    margin: 0 10px;
    color: #000;
    text-decoration: none;
    font-weight: 500;
   
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .copyright {
    margin-top: 20px;
    font-size: 15px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Student-Online-Registration/Header-and-Footer/customFooter.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,YAAY;IACZ,mBAAmB;EACrB;;EAEA;;IAEE,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,cAAc;IACd,WAAW;IACX,qBAAqB;IACrB,gBAAgB;;EAElB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB","sourcesContent":[".footer {\n    background-color: #ffe386;\n    padding: 20px;\n    text-align: center;\n  }\n  \n  .footer-content {\n    max-width: 800px;\n    margin: 0 auto;\n  }\n  \n  .logo {\n    width: 100px;\n    margin-bottom: 10px;\n  }\n  \n  .address,\n  .technical-assistance {\n    margin: 10px 0;\n    line-height: 27px;\n  }\n  \n  .footer-links a {\n    margin: 0 10px;\n    color: #000;\n    text-decoration: none;\n    font-weight: 500;\n   \n  }\n  \n  .footer-links a:hover {\n    text-decoration: underline;\n  }\n  \n  .copyright {\n    margin-top: 20px;\n    font-size: 15px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
