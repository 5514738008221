// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.online-form-step-box {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .online-form-step {
    position: relative;
    text-align: center;
    width: 100px;
  }
  
  .online-form-step-line {
    position: absolute;
    width: 65px;
    height: 1px;
    display: block;
    background-color: #ccc;
    left: 76px;
    top: 31%;
    transform: translateY(-50%);
  }
  
  .online-form-step h3 {
    color: #282c34;
    font-weight: bold;
    font-size: 13px;
  }
  
  .online-form-step span {
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: #f0f0f0;
    color: #000;
    display: inline-block;
    border-radius: 50px;
    overflow: hidden;
    padding: 15px;
    position: relative;
    z-index: 10;
    font-weight: bold;
  }
  
  .online-form-step.running span {
    cursor: default;
    color: #000;
    background-color: yellow;
  }
  
  .online-form-step.running h3 {
    color: #000;
  }
  
  .online-form-step.complete span {
    cursor: pointer;
    color: #fff;
    background-color: green;
  }
  
  .online-form-step.complete h3 {
    color: green;
  }
  
  .online-form-step.complete .form-step-line {
    background-color: green;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Student-Online-Registration/Online-Enrollment-Form/processbar.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,cAAc;IACd,sBAAsB;IACtB,UAAU;IACV,QAAQ;IACR,2BAA2B;EAC7B;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,WAAW;IACX,qBAAqB;IACrB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,wBAAwB;EAC1B;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,eAAe;IACf,WAAW;IACX,uBAAuB;EACzB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,uBAAuB;EACzB","sourcesContent":["\n.online-form-step-box {\n    display: flex;\n    justify-content: center;\n    gap: 15px;\n  }\n  \n  .online-form-step {\n    position: relative;\n    text-align: center;\n    width: 100px;\n  }\n  \n  .online-form-step-line {\n    position: absolute;\n    width: 65px;\n    height: 1px;\n    display: block;\n    background-color: #ccc;\n    left: 76px;\n    top: 31%;\n    transform: translateY(-50%);\n  }\n  \n  .online-form-step h3 {\n    color: #282c34;\n    font-weight: bold;\n    font-size: 13px;\n  }\n  \n  .online-form-step span {\n    cursor: pointer;\n    width: 50px;\n    height: 50px;\n    background-color: #f0f0f0;\n    color: #000;\n    display: inline-block;\n    border-radius: 50px;\n    overflow: hidden;\n    padding: 15px;\n    position: relative;\n    z-index: 10;\n    font-weight: bold;\n  }\n  \n  .online-form-step.running span {\n    cursor: default;\n    color: #000;\n    background-color: yellow;\n  }\n  \n  .online-form-step.running h3 {\n    color: #000;\n  }\n  \n  .online-form-step.complete span {\n    cursor: pointer;\n    color: #fff;\n    background-color: green;\n  }\n  \n  .online-form-step.complete h3 {\n    color: green;\n  }\n  \n  .online-form-step.complete .form-step-line {\n    background-color: green;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
