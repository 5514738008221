// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p.privacy-policy
{
    color:#900000;
    text-decoration:underline;
    cursor: pointer;
}
p.privacy-policy:hover
{
    color: #DFA414;
}`, "",{"version":3,"sources":["webpack://./src/pages/Student-Online-Registration/Create-Account/createAccount.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,yBAAyB;IACzB,eAAe;AACnB;AACA;;IAEI,cAAc;AAClB","sourcesContent":["p.privacy-policy\n{\n    color:#900000;\n    text-decoration:underline;\n    cursor: pointer;\n}\np.privacy-policy:hover\n{\n    color: #DFA414;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
